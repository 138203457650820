var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Vonalkód jóváhagyás")]), _vm.requests.length > 0 ? _c('v-card', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.requests,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `item.count`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-text-field', {
          attrs: {
            "disabled": item.approved == 1,
            "type": "number",
            "rules": _vm.numrules,
            "error-messages": _vm.errors['amount' + item.id]
          },
          model: {
            value: item.amount,
            callback: function ($$v) {
              _vm.$set(item, "amount", $$v);
            },
            expression: "item.amount"
          }
        })];
      }
    }, {
      key: `item.license`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return _vm._l(item.access_levels, function (al) {
          return _c('v-checkbox', {
            key: al.element_id,
            attrs: {
              "disabled": item.approved == 1,
              "label": al.name,
              "color": "orange"
            },
            model: {
              value: al.selected,
              callback: function ($$v) {
                _vm.$set(al, "selected", $$v);
              },
              expression: "al.selected"
            }
          });
        });
      }
    }, {
      key: `item.accept`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.approved == 0 ? _c('v-icon', [_vm._v("mdi-minus")]) : _c('v-icon', [_vm._v("mdi-check")])];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item.approved == 0 ? _c('v-btn', {
          staticClass: "rounded-sm m-1",
          attrs: {
            "fab": "",
            "small": "",
            "color": "secondary",
            "dark": "",
            "elevation": "0",
            "title": "Igénylés jóváhagyása"
          },
          on: {
            "click": function ($event) {
              return _vm.approve(item.id);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-check")])], 1) : _vm._e(), item.approved == 0 ? _c('v-btn', {
          staticClass: "rounded-sm m-1",
          attrs: {
            "fab": "",
            "small": "",
            "color": "secondary",
            "dark": "",
            "elevation": "0",
            "title": "Igénylés törlése"
          },
          on: {
            "click": function ($event) {
              return _vm.del(item.id);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1) : _vm._e()];
      }
    }], null, true)
  })], 1) : _c('div', [_vm._v("Nem található vonalkód igénylés.")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }