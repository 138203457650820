var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c('v-btn', {
    staticClass: "ml-1",
    attrs: {
      "tile": "",
      "depressed": "",
      "color": "grey",
      "width": "60",
      "height": "60"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-arrow-left")])], 1), _c('v-btn', {
    staticClass: "ml-1",
    attrs: {
      "tile": "",
      "depressed": "",
      "color": "accent",
      "width": "60",
      "height": "60"
    },
    on: {
      "click": _vm.save
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Hozzáférési szint")]), _c('v-card', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-security")]), _vm._v(" Alapadatok ")], 1), _c('v-card-text', [_c('v-row', {
    staticClass: "m-1"
  }, [_c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 12,
      "md": 6
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: _vm.$config.inputMasks.name,
      expression: "$config.inputMasks.name"
    }],
    attrs: {
      "error-messages": _vm.errors.name,
      "label": "Megnevezés",
      "filled": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    attrs: {
      "label": "Alias",
      "disabled": !_vm.form.erasable,
      "error-messages": _vm.errors.alias,
      "filled": ""
    },
    model: {
      value: _vm.form.alias,
      callback: function ($$v) {
        _vm.$set(_vm.form, "alias", $$v);
      },
      expression: "form.alias"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "Csoportok",
      "items": _vm.groups,
      "item-text": "name",
      "item-value": "element_id",
      "error-messages": _vm.errors.groups,
      "multiple": "",
      "chips": "",
      "hide-details": "",
      "filled": ""
    },
    model: {
      value: _vm.form.group_ids,
      callback: function ($$v) {
        _vm.$set(_vm.form, "group_ids", $$v);
      },
      expression: "form.group_ids"
    }
  }), _c('v-switch', {
    attrs: {
      "label": "QR kód engedélyezve",
      "error-messages": _vm.errors.barcode_enabled
    },
    model: {
      value: _vm.form.barcode_enabled,
      callback: function ($$v) {
        _vm.$set(_vm.form, "barcode_enabled", $$v);
      },
      expression: "form.barcode_enabled"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 12,
      "md": 6
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "Megjegyzés",
      "error-messages": _vm.errors.comment,
      "filled": "",
      "rows": 3,
      "auto-grow": ""
    },
    model: {
      value: _vm.form.comment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-chip")]), _vm._v(" Eszközök ")], 1), _c('v-card-text', [_c('v-multi-select-list', {
    attrs: {
      "headers": [{
        text: 'Eszköz',
        value: 'device'
      }, {
        text: 'Engedélyezve',
        value: 'schedule_allow'
      }, {
        text: 'Letiltva',
        value: 'schedule_deny'
      }],
      "items": _vm.elements,
      "item-text": "name",
      "item-value": "element_id",
      "blank-item-template": {
        element_id: 0,
        schedule_group_id_allow: 0,
        schedule_group_id_deny: 0
      },
      "unique-values": ""
    },
    scopedSlots: _vm._u([{
      key: `item.device`,
      fn: function (_ref) {
        var item = _ref.item,
          items = _ref.items;
        return [_c('v-autocomplete', {
          attrs: {
            "placeholder": "Eszköz",
            "items": items,
            "item-text": "name",
            "item-value": "element_id",
            "filled": "",
            "hide-details": "",
            "required": "",
            "value-comparator": _vm.$config.comparators.isEqual,
            "loading": _vm.elements.length == 0
          },
          model: {
            value: item.element_id,
            callback: function ($$v) {
              _vm.$set(item, "element_id", $$v);
            },
            expression: "item.element_id"
          }
        })];
      }
    }, {
      key: `item.schedule_allow`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-autocomplete', {
          attrs: {
            "placeholder": "Soha",
            "items": _vm.scheduleGroups,
            "item-value": "id",
            "item-text": "name",
            "filled": "",
            "hide-details": "",
            "clearable": ""
          },
          model: {
            value: item.schedule_group_id_allow,
            callback: function ($$v) {
              _vm.$set(item, "schedule_group_id_allow", $$v);
            },
            expression: "item.schedule_group_id_allow"
          }
        })];
      }
    }, {
      key: `item.schedule_deny`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-autocomplete', {
          attrs: {
            "placeholder": "Soha",
            "items": _vm.scheduleGroups,
            "item-value": "id",
            "item-text": "name",
            "filled": "",
            "hide-details": "",
            "clearable": ""
          },
          model: {
            value: item.schedule_group_id_deny,
            callback: function ($$v) {
              _vm.$set(item, "schedule_group_id_deny", $$v);
            },
            expression: "item.schedule_group_id_deny"
          }
        })];
      }
    }], null, true),
    model: {
      value: _vm.form.elements,
      callback: function ($$v) {
        _vm.$set(_vm.form, "elements", $$v);
      },
      expression: "form.elements"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-6",
    attrs: {
      "loading": _vm.cards.length == 0
    }
  }, [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-card-account-details-outline")]), _vm._v(" Kártyák hozzárendelése")], 1), _c('v-card-text', [_c('v-two-panel-multiselect', {
    attrs: {
      "headers": [{
        text: 'Kártyaszám',
        value: 'card_number'
      }, {
        text: 'Birtokos',
        value: 'owner_name'
      }, {
        text: 'RFID',
        value: 'rf_id'
      }],
      "search-fields": ['card_number', 'owner_name', 'rf_id'],
      "options": {
        sortBy: ['card_number']
      },
      "items": _vm.cards,
      "item-value": "element_id"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref4) {
        var search = _ref4.search;
        return [_c('v-card', {
          staticClass: "mb-6",
          attrs: {
            "outlined": ""
          }
        }, [_c('v-card-title', [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-filter")]), _vm._v(" Keresés ")], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": 12,
            "md": 6,
            "lg": 3
          }
        }, [_c('v-text-field', {
          attrs: {
            "label": "Kártyatulajdonos",
            "hide-details": ""
          },
          model: {
            value: search.owner_name,
            callback: function ($$v) {
              _vm.$set(search, "owner_name", $$v);
            },
            expression: "search.owner_name"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": 12,
            "md": 6,
            "lg": 3
          }
        }, [_c('v-text-field', {
          attrs: {
            "label": "Kártyaszám",
            "hide-details": ""
          },
          model: {
            value: search.card_number,
            callback: function ($$v) {
              _vm.$set(search, "card_number", $$v);
            },
            expression: "search.card_number"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": 12,
            "md": 6,
            "lg": 3
          }
        }, [_c('v-text-field', {
          attrs: {
            "label": "RFID",
            "hide-details": ""
          },
          model: {
            value: search.rf_id,
            callback: function ($$v) {
              _vm.$set(search, "rf_id", $$v);
            },
            expression: "search.rf_id"
          }
        })], 1)], 1)], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.form.cards,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cards", $$v);
      },
      expression: "form.cards"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }