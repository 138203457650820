var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value ? _c('v-dialog', {
    attrs: {
      "value": true,
      "persistent": "",
      "max-width": "480px"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-file-import")]), _vm._v(" Kulcs importálás "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-btn', {
    staticClass: "my-5",
    attrs: {
      "href": "/assets/doc/kulcs_import_sablon.xls",
      "download": "",
      "large": "",
      "depressed": "",
      "block": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-file-download")]), _vm._v(" Sablon XLS fájl letöltése ")], 1), _c('file-upload-input', {
    attrs: {
      "elevation": "0",
      "color": "muted",
      "accept": ".xls, .xlsx"
    },
    model: {
      value: _vm.files,
      callback: function ($$v) {
        _vm.files = $$v;
      },
      expression: "files"
    }
  }), _vm.errors.file ? _c('v-alert', {
    attrs: {
      "type": "error",
      "text": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.errors.file[0]) + " ")]) : _vm._e()], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "large": "",
      "depressed": "",
      "disabled": _vm.loading,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" Importálás ")]), _c('v-spacer')], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }