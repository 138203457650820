var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._t("header", null, null, {
    search: _vm.search
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v("Kiválasztott elemek")]), _c('v-data-table', {
    attrs: {
      "headers": [].concat(_vm.headers, [{
        value: 'actions',
        width: '56px',
        sortable: false
      }]),
      "items": _vm.selectedFilteredItems,
      "options": _vm.tableOptions
    },
    on: {
      "update:options": function ($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: `item.actions`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-btn', {
          staticClass: "rounded-sm",
          attrs: {
            "fab": "",
            "small": "",
            "depressed": "",
            "color": "secondary",
            "title": "Eltávolítás"
          },
          on: {
            "click": function ($event) {
              return _vm.removeItem(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-minus")])], 1)];
      }
    }, _vm._l(_vm.headers, function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var item = _ref2.item,
            index = _ref2.index;
          return [_vm._t(`item.${header.value}`, null, null, {
            item,
            index
          })];
        }
      };
    })], null, true)
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v("Választható elemek")]), _c('v-data-table', {
    attrs: {
      "headers": [{
        value: 'actions',
        width: '56px',
        sortable: false
      }].concat(_vm.headers),
      "items": _vm.remainingItems,
      "options": _vm.tableOptions
    },
    on: {
      "update:options": function ($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers, function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref3) {
          var item = _ref3.item,
            index = _ref3.index;
          return [_vm._t(`item.${header.value}`, null, null, {
            item,
            index
          })];
        }
      };
    }), {
      key: `item.actions`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          staticClass: "rounded-sm",
          attrs: {
            "fab": "",
            "small": "",
            "depressed": "",
            "color": "secondary",
            "title": "Hozzáadás"
          },
          on: {
            "click": function ($event) {
              return _vm.selectedItems.push(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    }], null, true)
  })], 1)], 1)], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }