var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    staticClass: "elevation-1",
    attrs: {
      "width": _vm.sidebarWidth,
      "app": "",
      "floating": "",
      "fixed": "",
      "mobile-breakpoint": 840
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function () {
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'Home'
            }
          }
        }, [_c('v-hover', {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref) {
              var hover = _ref.hover;
              return [_c('v-sheet', {
                staticClass: "d-flex align-center",
                attrs: {
                  "fab": "",
                  "tile": "",
                  "width": _vm.sidebarWidth,
                  "height": _vm.headerHeight,
                  "color": `grey darken-${hover ? 2 : 1}`,
                  "dark": ""
                }
              }, [_c('v-img', {
                staticClass: "mx-4",
                attrs: {
                  "max-width": "44",
                  "contain": "",
                  "src": require('../../assets/logo.svg')
                }
              }), _c('div', {
                staticClass: "text-left"
              }, [_c('div', {
                staticClass: "font-weight-bold"
              }, [_vm._v("UNIOM")]), _c('div', {
                staticClass: "caption"
              }, [_vm._v("Beléptetőrendszer")])])], 1)];
            }
          }])
        })], 1), _c('v-sheet', {
          attrs: {
            "height": "4",
            "color": "primary darken-1"
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function () {
        return [_c('v-divider', {
          staticClass: "ma-0"
        }), _c('v-sheet', {
          staticClass: "d-flex justify-center align-center pa-3",
          attrs: {
            "flat": "",
            "dark": "",
            "height": _vm.footerHeight
          }
        }, [_c('v-btn', {
          attrs: {
            "large": "",
            "text": "",
            "block": ""
          },
          on: {
            "click": _vm.$logout
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-exit-run")]), _vm._v(" Exit ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-list', {
    attrs: {
      "dense": "",
      "nav": ""
    }
  }, [_vm._l(_vm.menu, function (item, i) {
    var _item$to;
    return [!item.children && _vm.hasRight(item.right) ? _c('v-list-item', {
      key: `menu-item-${i}`,
      staticClass: "white",
      class: {
        'primary': _vm.matchRoute(item === null || item === void 0 ? void 0 : (_item$to = item.to) === null || _item$to === void 0 ? void 0 : _item$to.name),
        'white--text': !_vm.$store.state.darkMode && _vm.matchRoute(item.to ? item.to.name : null)
      },
      attrs: {
        "to": item.link || item.to
      }
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(item.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.label))])], 1)], 1) : _vm.hasRight(item.right) ? _c('v-list-group', {
      key: `menu-item-group-${i}`,
      staticClass: "rounded elevation-0 white my-1",
      attrs: {
        "prepend-icon": item.icon,
        "value": _vm.matchChildRoute(item.group),
        "color": "primary"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c('v-list-item-title', [_vm._v(_vm._s(item.label))])];
        },
        proxy: true
      }], null, true)
    }, [_c('v-list', {
      staticClass: "px-2"
    }, [_vm._l(item.children, function (child) {
      var _child$to;
      return [_vm.hasRight(child.right) ? _c('v-list-item', {
        key: child.label,
        class: {
          'primary white--text': _vm.matchRoute(child === null || child === void 0 ? void 0 : (_child$to = child.to) === null || _child$to === void 0 ? void 0 : _child$to.name)
        },
        attrs: {
          "to": child.link || child.to
        }
      }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(child.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
        domProps: {
          "textContent": _vm._s(child.label)
        }
      })], 1)], 1) : _vm._e()];
    })], 2)], 1) : _vm._e()];
  })], 2), _c('v-sheet', {
    attrs: {
      "height": "100",
      "color": "transparent"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }