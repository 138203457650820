var render = function render(){
  var _vm$cards$search;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', [_vm._v("mdi-arrow-left")])], 1), _c('v-btn', _vm._b({
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', [_vm._v("mdi-content-save")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Rendszám")]), _vm.loading ? _c('loading-overlay') : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12,
      "md": 5
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Alapadatok")]), _c('v-card-text', [_c('CardAutocomplete', {
    attrs: {
      "label": "Kártya",
      "items": _vm.cards.items,
      "item-value": "element_id",
      "filled": "",
      "loading": _vm.cards.loading,
      "search-input": _vm.cards.search,
      "value-comparator": _vm.$config.comparators.isEqual,
      "error-messages": _vm.errors.card_id,
      "no-filter": "",
      "clearable": "",
      "hide-no-data": ((_vm$cards$search = _vm.cards.search) === null || _vm$cards$search === void 0 ? void 0 : _vm$cards$search.length) < 3 || _vm.cards.loading
    },
    on: {
      "update:searchInput": function ($event) {
        return _vm.$set(_vm.cards, "search", $event);
      },
      "update:search-input": function ($event) {
        return _vm.$set(_vm.cards, "search", $event);
      },
      "click:clear": function ($event) {
        _vm.cards.items = [];
      }
    },
    model: {
      value: _vm.form.card_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "card_id", $$v);
      },
      expression: "form.card_id"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "lowercase",
      rawName: "v-lowercase"
    }],
    attrs: {
      "label": "Email cím",
      "error-messages": _vm.errors.email,
      "filled": ""
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _c('v-masked-text-field', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    attrs: {
      "label": "Rendszám",
      "error-messages": _vm.errors.number,
      "mask": _vm.$config.inputMasks.plateNumber,
      "filled": ""
    },
    model: {
      value: _vm.form.number,
      callback: function ($$v) {
        _vm.$set(_vm.form, "number", $$v);
      },
      expression: "form.number"
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }