var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "container my-auto pb-12"
  }, [_c('div', {
    attrs: {
      "id": "login-modal"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "24",
      "outlined": ""
    }
  }, [_c('v-card', {
    staticClass: "text-center pa-8 px-sm-12",
    attrs: {
      "id": "header",
      "tile": "",
      "color": "muted"
    }
  }, [_c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "width": "120",
      "src": require('../../assets/logo.svg')
    }
  })], 1), _c('h1', {
    staticClass: "my-4 pa-0 text-uppercase"
  }, [_vm._v("BR"), _c('span', [_vm._v(" - Beléptető rendszer")])]), _c('small', [_vm._v("DirektGuard beléptető menedzsment felülete.")])]), _c('v-card', {
    attrs: {
      "id": "body",
      "tile": "",
      "elevation": "0",
      "loading": _vm.loading
    }
  }, [_c('div', {
    staticClass: "pa-8 px-sm-12"
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.login.apply(null, arguments);
      }
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Email",
      "type": "email",
      "filled": "",
      "dense": "",
      "error-messages": _vm.errors.email,
      "autofocus": ""
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Jelszó",
      "filled": "",
      "error-messages": _vm.errors.password,
      "type": _vm.showPassword ? 'text' : 'password',
      "append-icon": _vm.showPassword ? 'mdi-eye' : 'mdi-eye-off'
    },
    on: {
      "click:append": function ($event) {
        _vm.showPassword = !_vm.showPassword;
      }
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), _c('v-expand-transition', [_vm.errors.login && _vm.errors.login.length ? _c('v-alert', {
    attrs: {
      "dense": "",
      "filled": "",
      "dismissible": "",
      "border": "left",
      "type": "error"
    }
  }, [_vm._l(_vm.errors.login, function (error) {
    return [_vm._v(" " + _vm._s(error) + " ")];
  })], 2) : _vm._e(), _vm.$route.params.message ? _c('v-alert', {
    attrs: {
      "dense": "",
      "text": "",
      "type": "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.$route.params.message) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex flex-column justify-center align-center"
  }, [_c('v-btn', {
    attrs: {
      "type": "submit",
      "color": "accent",
      "x-large": "",
      "depressed": "",
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-login-variant")]), _vm._v(" Bejelentkezés ")], 1)], 1)], 1)])])], 1), _c('div', {
    staticClass: "text-center",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('small', {
    staticClass: "text-caption white--text",
    attrs: {
      "title": `Build #${_vm.buildNumber}\n${_vm.buildDate}`
    }
  }, [_vm._v(_vm._s(_vm.version))])])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }