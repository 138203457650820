var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c('v-btn', {
    staticClass: "ml-1",
    attrs: {
      "tile": "",
      "elevation": "0",
      "color": "accent",
      "width": "60",
      "height": "60",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Nyitvatartás")]), _c('v-expansion-panels', {
    staticClass: "mb-4",
    attrs: {
      "value": 0
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c('v-expansion-panel-content', [_c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": 12,
      "md": 4,
      "sm": 12
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Típus",
      "items": _vm.elementTypes,
      "item-text": "name",
      "item-value": "id",
      "placeholder": "Válassz"
    },
    on: {
      "change": _vm.fetchElements
    },
    model: {
      value: _vm.elementTypeId,
      callback: function ($$v) {
        _vm.elementTypeId = $$v;
      },
      expression: "elementTypeId"
    }
  })], 1), _c('v-expand-transition', [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.elements.length,
      expression: "elements.length"
    }],
    attrs: {
      "cols": 12,
      "md": 4,
      "sm": 12
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Eszköz",
      "items": _vm.elements,
      "item-text": "name",
      "item-value": "element_id"
    },
    on: {
      "change": _vm.fetchDates
    },
    scopedSlots: _vm._u([_vm._l(['selection', 'item'], function (slot) {
      return {
        key: slot,
        fn: function (_ref) {
          var item = _ref.item;
          return [_vm._v(" " + _vm._s(item.name) + " (" + _vm._s(item.openings.length) + ") ")];
        }
      };
    })], null, true),
    model: {
      value: _vm.elementId,
      callback: function ($$v) {
        _vm.elementId = $$v;
      },
      expression: "elementId"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-expand-transition', [_c('v-alert', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.openWarning,
      expression: "openWarning"
    }],
    staticClass: "mb-6",
    attrs: {
      "color": "error",
      "type": "error",
      "icon": "mdi-alert-outline",
      "elevation": "0",
      "dark": "",
      "prominent": ""
    }
  }, [_c('b', [_vm._v("FIGYELEM!")]), _c('br'), _vm._v(" Ebben az ajtóban olyan zár található, melynek tartós nyitva tartása annak meghibásodását okozhatja. "), _c('br'), _vm._v(" Ennek az opciónak a használata itt nem javasolt. ")])], 1), _c('v-expand-transition', [_c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.elementId,
      expression: "elementId"
    }]
  }, [_c('v-card-text', [_c('v-card', {
    staticClass: "mb-6",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v("Nap")]), _c('v-card-text', [_c('v-multi-select-list', {
    attrs: {
      "headers": [{
        text: 'Nap',
        value: 'day'
      }, {
        text: 'Időponttól',
        value: 'time_from'
      }, {
        text: 'Időpontig',
        value: 'time_to'
      }],
      "items": _vm.days,
      "blank-item-template": {
        day: null,
        time_from: '00:00',
        time_to: '23:59'
      }
    },
    scopedSlots: _vm._u([{
      key: `item.day`,
      fn: function (_ref2) {
        var items = _ref2.items,
          item = _ref2.item;
        return [_c('v-select', {
          attrs: {
            "items": items,
            "hide-details": "",
            "dense": "",
            "filled": "",
            "placeholder": "Válasszon",
            "value-comparator": _vm.$config.comparators.isEqual
          },
          model: {
            value: item.day,
            callback: function ($$v) {
              _vm.$set(item, "day", $$v);
            },
            expression: "item.day"
          }
        })];
      }
    }, _vm._l(['time_from', 'time_to'], function (slot) {
      return {
        key: `item.${slot}`,
        fn: function (_ref3) {
          var item = _ref3.item;
          return [_c('v-datetime-picker', {
            key: slot,
            attrs: {
              "dense": "",
              "date-picker": false,
              "filled": "",
              "hide-details": ""
            },
            model: {
              value: item[slot],
              callback: function ($$v) {
                _vm.$set(item, slot, $$v);
              },
              expression: "item[slot]"
            }
          })];
        }
      };
    })], null, true),
    model: {
      value: _vm.items,
      callback: function ($$v) {
        _vm.items = $$v;
      },
      expression: "items"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mb-6",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v("Dátum")]), _c('v-card-text', [_c('v-multi-select-list', {
    attrs: {
      "headers": [{
        text: 'Dátumtól',
        value: 'date_from'
      }, {
        text: 'Időponttól',
        value: 'time_from'
      }, {
        text: 'Dátumig',
        value: 'date_to'
      }, {
        text: 'Időpontig',
        value: 'time_to'
      }, {
        text: 'Zárva',
        value: 'close'
      }],
      "items": _vm.days,
      "blank-item-template": {
        date_from: null,
        date_to: null,
        time_from: '00:00',
        time_to: '23:59',
        close: {
          selected: false
        }
      }
    },
    scopedSlots: _vm._u([{
      key: `item.date_from`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-datetime-picker', {
          attrs: {
            "time-picker": false,
            "dense": "",
            "placeholder": "Válasszon",
            "hide-details": "",
            "filled": ""
          },
          model: {
            value: item.date_from,
            callback: function ($$v) {
              _vm.$set(item, "date_from", $$v);
            },
            expression: "item.date_from"
          }
        })];
      }
    }, {
      key: `item.date_to`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-datetime-picker', {
          attrs: {
            "time-picker": false,
            "dense": "",
            "placeholder": "Válasszon",
            "hide-details": "",
            "filled": ""
          },
          model: {
            value: item.date_to,
            callback: function ($$v) {
              _vm.$set(item, "date_to", $$v);
            },
            expression: "item.date_to"
          }
        })];
      }
    }, {
      key: `item.time_from`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('v-datetime-picker', {
          attrs: {
            "date-picker": false,
            "dense": "",
            "hide-details": "",
            "filled": ""
          },
          model: {
            value: item.time_from,
            callback: function ($$v) {
              _vm.$set(item, "time_from", $$v);
            },
            expression: "item.time_from"
          }
        })];
      }
    }, {
      key: `item.time_to`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('v-datetime-picker', {
          attrs: {
            "date-picker": false,
            "dense": "",
            "hide-details": "",
            "filled": ""
          },
          model: {
            value: item.time_to,
            callback: function ($$v) {
              _vm.$set(item, "time_to", $$v);
            },
            expression: "item.time_to"
          }
        })];
      }
    }, {
      key: `item.close`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('v-checkbox', {
          attrs: {
            "off-icon": "mdi-lock-open-variant-outline",
            "on-icon": "mdi-lock-outline",
            "color": item.close.selected ? 'error' : 'success',
            "title": item.close.selected ? 'Zárva' : 'Nyitva'
          },
          model: {
            value: item.close.selected,
            callback: function ($$v) {
              _vm.$set(item.close, "selected", $$v);
            },
            expression: "item.close.selected"
          }
        })];
      }
    }], null, true),
    model: {
      value: _vm.items_,
      callback: function ($$v) {
        _vm.items_ = $$v;
      },
      expression: "items_"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }