var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "disabled": _vm.options.page != 1,
      "title": _vm.options.page == 1 ? '' : 'A frissítés opció csak az első oldalon működik',
      "loading": _vm.loading
    },
    on: {
      "click": _vm.togglePolling
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_vm.polling ? _c('v-icon', [_vm._v("mdi-stop")]) : _c('v-icon', [_vm._v("mdi-play")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Parkoló napló")]), _c('v-expansion-panels', {
    staticClass: "mb-4",
    attrs: {
      "mandatory": !!Object.values(_vm.search).find(function (e) {
        return e !== '';
      })
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c('v-expansion-panel-content', [_c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": 12,
      "md": 3,
      "lg": 2
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Parkoló",
      "items": _vm.buildings,
      "item-text": "name",
      "item-value": "id",
      "dense": "",
      "clearable": "",
      "hide-details": "",
      "placeholder": "Összes",
      "multiple": ""
    },
    on: {
      "change": _vm.searchChangeEventHandler
    },
    model: {
      value: _vm.search.building_id,
      callback: function ($$v) {
        _vm.$set(_vm.search, "building_id", $$v);
      },
      expression: "search.building_id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 3,
      "lg": 2
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Kontroller",
      "items": _vm.controllers,
      "item-text": "name",
      "item-value": "element_id",
      "dense": "",
      "clearable": "",
      "hide-details": "",
      "placeholder": "Összes",
      "multiple": ""
    },
    on: {
      "change": _vm.searchChangeEventHandler
    },
    model: {
      value: _vm.search.controller_id,
      callback: function ($$v) {
        _vm.$set(_vm.search, "controller_id", $$v);
      },
      expression: "search.controller_id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 3,
      "lg": 2
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Típus",
      "items": _vm.logTypes,
      "item-text": "label",
      "item-value": "value",
      "dense": "",
      "hide-details": "",
      "clearable": "",
      "placeholder": "Összes"
    },
    on: {
      "change": _vm.searchChangeEventHandler
    },
    model: {
      value: _vm.search.log_type,
      callback: function ($$v) {
        _vm.$set(_vm.search, "log_type", $$v);
      },
      expression: "search.log_type"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 3,
      "lg": 3
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Név / Kártyaszám / Vonalkód száma / Rendszám",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchChangeEventHandler
    },
    model: {
      value: _vm.search.keyphrase,
      callback: function ($$v) {
        _vm.$set(_vm.search, "keyphrase", $$v);
      },
      expression: "search.keyphrase"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 3,
      "lg": _vm.search.period === 'custom' ? 1 : 2
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Időkorlát",
      "items": _vm.timePeriods,
      "item-text": "label",
      "item-value": "value",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.searchChangeEventHandler
    },
    model: {
      value: _vm.search.period,
      callback: function ($$v) {
        _vm.$set(_vm.search, "period", $$v);
      },
      expression: "search.period"
    }
  })], 1), _vm.search.period === 'custom' ? [_c('v-col', {
    attrs: {
      "cols": 12,
      "md": 3,
      "lg": 2
    }
  }, [_c('v-datetime-picker', {
    attrs: {
      "label": "Időkorlát kezdete",
      "clearable": "",
      "dense": "",
      "date-picker-props": {
        allowedDates: _vm.allowedStartDates
      },
      "time-picker": false,
      "error-messages": _vm.search.from ? '' : 'Kötelező!'
    },
    on: {
      "change": _vm.searchChangeEventHandler
    },
    model: {
      value: _vm.search.from,
      callback: function ($$v) {
        _vm.$set(_vm.search, "from", $$v);
      },
      expression: "search.from"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 2,
      "lg": 2
    }
  }, [_c('v-datetime-picker', {
    attrs: {
      "label": "Időkorlát vége",
      "clearable": "",
      "dense": "",
      "date-picker-props": {
        allowedDates: _vm.allowedEndDates
      },
      "time-picker": false,
      "disabled": !_vm.search.from,
      "error-messages": _vm.search.from && !_vm.search.to ? 'Kötelező!' : ''
    },
    on: {
      "change": _vm.searchChangeEventHandler
    },
    model: {
      value: _vm.search.to,
      callback: function ($$v) {
        _vm.$set(_vm.search, "to", $$v);
      },
      expression: "search.to"
    }
  })], 1)] : _vm._e()], 2)], 1)], 1)], 1), _c('v-card', [_c('v-data-table', {
    attrs: {
      "loading": _vm.dataTable.loading,
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps,
      "calculate-widths": ""
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: `item.timestamp`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', {
          attrs: {
            "title": _vm._f("moment")(item.timestamp, _vm.$config.momentFormats.explicit)
          }
        }, [_vm._v(_vm._s(_vm._f("moment")(item.timestamp, 'lll')))])];
      }
    }, {
      key: `item.number`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.card_number ? item.card_rule_prefix + item.card_number : item.barcode_number) + " ")];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.element_id && _vm.hasRight('CARD_READ') ? _c('v-btn', {
          staticClass: "rounded-sm",
          attrs: {
            "to": {
              name: 'CardEditor',
              params: {
                id: item.element_id
              }
            },
            "fab": "",
            "small": "",
            "color": "secondary",
            "dark": "",
            "text": ""
          }
        }, [_c('v-icon', [_vm._v("mdi-card-account-details")])], 1) : _vm._e()];
      }
    }], null, true)
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }