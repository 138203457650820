var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "title": "Vissza"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-arrow-left")])], 1), _c('v-btn', _vm._b({
    attrs: {
      "disabled": _vm.loading,
      "title": "Mentés"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Kamera")]), _c('v-card', {
    staticClass: "mb-6",
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-cctv")]), _vm._v(" Alapadatok ")], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Megnevezés",
      "error-messages": _vm.errors.name,
      "filled": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "Kapcsolat",
      "error-messages": _vm.errors.connection_id,
      "items": _vm.connections,
      "item-text": "name",
      "item-value": "element_id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    model: {
      value: _vm.form.connection_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "connection_id", $$v);
      },
      expression: "form.connection_id"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Stream szerver cím",
      "error-messages": _vm.errors.webrtc_stream_server_address,
      "filled": ""
    },
    model: {
      value: _vm.form.webrtc_stream_server_address,
      callback: function ($$v) {
        _vm.$set(_vm.form, "webrtc_stream_server_address", $$v);
      },
      expression: "form.webrtc_stream_server_address"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Stream azonosító",
      "error-messages": _vm.errors.webrtc_stream_id,
      "filled": ""
    },
    model: {
      value: _vm.form.webrtc_stream_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "webrtc_stream_id", $$v);
      },
      expression: "form.webrtc_stream_id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "Megjegyzés",
      "error-messages": _vm.errors.comment,
      "filled": "",
      "rows": 3,
      "auto-grow": ""
    },
    model: {
      value: _vm.form.comment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12,
      "md": 3,
      "sm": 6
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Épület",
      "error-messages": _vm.errors.building_id,
      "items": _vm.buildings,
      "item-text": "name",
      "item-value": "id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    model: {
      value: _vm.form.building_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "building_id", $$v);
      },
      expression: "form.building_id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 3,
      "sm": 6
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Épület szint",
      "error-messages": _vm.errors.building_level_id,
      "items": _vm.buildingLevels,
      "item-text": "name",
      "item-value": "id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual,
      "disabled": !_vm.form.building_id,
      "persistent-hint": !_vm.form.building_id,
      "hint": _vm.form.building_id ? '' : 'Épület kötelező'
    },
    model: {
      value: _vm.form.building_level_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "building_level_id", $$v);
      },
      expression: "form.building_level_id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 3,
      "sm": 6
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "X koordináta",
      "error-messages": _vm.errors.x_coord,
      "filled": "",
      "type": "number"
    },
    model: {
      value: _vm.form.x_coord,
      callback: function ($$v) {
        _vm.$set(_vm.form, "x_coord", $$v);
      },
      expression: "form.x_coord"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 3,
      "sm": 6
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Y koordináta",
      "error-messages": _vm.errors.y_coord,
      "filled": "",
      "type": "number"
    },
    model: {
      value: _vm.form.y_coord,
      callback: function ($$v) {
        _vm.$set(_vm.form, "y_coord", $$v);
      },
      expression: "form.y_coord"
    }
  })], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-television")]), _vm._v(" Élő nézet ")], 1), _c('v-card-text', [_c('div', {
    attrs: {
      "id": "lpr-overlay"
    }
  }, [_vm.form.webrtc_stream_server_address ? _c('stream-player', {
    attrs: {
      "server-address": _vm.form.webrtc_stream_server_address,
      "stream-id": _vm.form.webrtc_stream_id,
      "channel": 0
    }
  }) : _vm._e(), _c('canvas', {
    ref: "canvas",
    attrs: {
      "width": "1920",
      "height": "1080"
    }
  })], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-vector-polygon-variant")]), _vm._v(" Rendszám zónák ")], 1), _c('v-card-text', [_c('v-multi-select-list', {
    attrs: {
      "headers": [{
        text: 'Poligon',
        value: 'polygon'
      }, {
        text: 'Sorompó',
        value: 'gate_id'
      }],
      "items": _vm.barriers,
      "blank-item-template": {
        name: '',
        polygon: ''
      }
    },
    scopedSlots: _vm._u([{
      key: `item.polygon`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-text-field', {
          attrs: {
            "hide-details": "",
            "filled": "",
            "dense": ""
          },
          model: {
            value: item.polygon,
            callback: function ($$v) {
              _vm.$set(item, "polygon", $$v);
            },
            expression: "item.polygon"
          }
        })];
      }
    }, {
      key: `item.gate_id`,
      fn: function (_ref2) {
        var item = _ref2.item,
          items = _ref2.items;
        return [_c('v-autocomplete', {
          attrs: {
            "items": items,
            "item-text": "name",
            "item-value": "element_id",
            "placeholder": "Válassz",
            "hide-details": "",
            "filled": "",
            "dense": "",
            "clearable": ""
          },
          model: {
            value: item.gate_id,
            callback: function ($$v) {
              _vm.$set(item, "gate_id", $$v);
            },
            expression: "item.gate_id"
          }
        })];
      }
    }], null, true),
    model: {
      value: _vm.form.lpr_zones,
      callback: function ($$v) {
        _vm.$set(_vm.form, "lpr_zones", $$v);
      },
      expression: "form.lpr_zones"
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }