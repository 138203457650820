var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', [_vm._v("mdi-arrow-left")])], 1), _c('v-btn', _vm._b({
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Sorompó")]), _c('v-card', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-boom-gate")]), _vm._v(" Alapadatok ")], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Megnevezés",
      "error-messages": _vm.errors.name,
      "filled": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "Kontroller",
      "error-messages": _vm.errors.controller_id,
      "items": _vm.controllers,
      "item-text": "name",
      "item-value": "element_id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    on: {
      "change": _vm.controllerChangedEventHandler
    },
    model: {
      value: _vm.form.controller_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "controller_id", $$v);
      },
      expression: "form.controller_id"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "Olvasó",
      "error-messages": _vm.errors.reader_id,
      "items": _vm.readers,
      "item-text": "name",
      "item-value": "element_id",
      "filled": "",
      "hint": _vm.form.controller_id ? '' : 'Kontroller kötelező',
      "persistent-hint": !_vm.form.controller_id,
      "disabled": !_vm.form.controller_id,
      "value-comparator": _vm.$config.comparators.isEqual
    },
    model: {
      value: _vm.form.reader_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reader_id", $$v);
      },
      expression: "form.reader_id"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "Relé",
      "error-messages": _vm.errors.relay_id,
      "items": _vm.relays,
      "item-text": "name",
      "item-value": "element_id",
      "filled": "",
      "hint": _vm.form.controller_id ? '' : 'Kontroller kötelező',
      "persistent-hint": !_vm.form.controller_id,
      "disabled": !_vm.form.controller_id,
      "value-comparator": _vm.$config.comparators.isEqual
    },
    model: {
      value: _vm.form.relay_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "relay_id", $$v);
      },
      expression: "form.relay_id"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Videó stream URL",
      "error-messages": _vm.errors.camera_video_stream,
      "filled": ""
    },
    model: {
      value: _vm.form.camera_video_stream,
      callback: function ($$v) {
        _vm.$set(_vm.form, "camera_video_stream", $$v);
      },
      expression: "form.camera_video_stream"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "NCIOM Control Seq ID",
      "error-messages": _vm.errors.nciom_control_seq_id,
      "filled": ""
    },
    model: {
      value: _vm.form.nciom_control_seq_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nciom_control_seq_id", $$v);
      },
      expression: "form.nciom_control_seq_id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "Megjegyzés",
      "error-messages": _vm.errors.comment,
      "filled": "",
      "auto-grow": "",
      "rows": 3
    },
    model: {
      value: _vm.form.comment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Kiegészítő eszköz"
    },
    model: {
      value: _vm.form.additional,
      callback: function ($$v) {
        _vm.$set(_vm.form, "additional", $$v);
      },
      expression: "form.additional"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Megnevezés kiegészítés",
      "error-messages": _vm.errors.name_addition,
      "filled": ""
    },
    model: {
      value: _vm.form.name_addition,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name_addition", $$v);
      },
      expression: "form.name_addition"
    }
  }), _c('v-row', {
    staticClass: "m-1"
  }, [_c('v-col', {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "X koordináta",
      "error-messages": _vm.errors.x_coord,
      "type": "number",
      "filled": ""
    },
    model: {
      value: _vm.form.x_coord,
      callback: function ($$v) {
        _vm.$set(_vm.form, "x_coord", $$v);
      },
      expression: "form.x_coord"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Y koordináta",
      "error-messages": _vm.errors.y_coord,
      "type": "number",
      "filled": ""
    },
    model: {
      value: _vm.form.y_coord,
      callback: function ($$v) {
        _vm.$set(_vm.form, "y_coord", $$v);
      },
      expression: "form.y_coord"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }