<template>
  <div>
    <title-row title="Épületek">
      <template #buttons>
        <title-row-button v-if="hasRight('DEVICE_WRITE')" :to="{ name: 'NewBuilding' }">
          <v-icon left>mdi-plus</v-icon>
          Új épület
        </title-row-button>
      </template>
    </title-row>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field v-model="search.name" @input="searchEventHandler" @click:clear="searchEventHandler"
                label="Név" clearable />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field v-model="search.type" @input="searchEventHandler" @click:clear="searchEventHandler"
                label="Típus" clearable />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table :loading="loading" :headers="dataTable.headers" :items="dataTable.items"
        :server-items-length="dataTable.itemsLength" :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps" calculate-widths>
        <template #[`item.actions`]="{ item }">
          <v-btn v-if="hasRight('DEVICE_WRITE')" small text fab dark depressed color="primary" class="rounded-sm"
            :to="{ name: 'BuildingEditor', params: { id: item.id } }" title="Szerkesztés">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn v-if="hasRight('DEVICE_WRITE')" text fab small color="primary" class="rounded-sm m-3"
            @click="deleteBuilding(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  data() {
    return {
      routeName: 'BuildingList',
      fetchItems: this.fetchBuildings,
      dataTable: {
        options: {
          sortBy: ['name'],
        },
        headers: [
          { text: 'Megnevezés', value: 'name' },
          { text: 'Típus', value: 'type' },
          { value: 'actions', sortable: false, align: 'end' }
        ],
      },
      search: {
        name: '',
        type: ''
      }
    };
  },

  methods: {
    parseSearchQueries() {
      return {
        name: this.$route.query.name,
        type: this.$route.query.type
      };
    },

    async fetchBuildings() {
      this.loading = true;
      try {
        const response = await this.$http.post('buildings/list', this.options);
        this.dataTable.items = response.buildings;
        this.dataTable.itemsLength = response.buildings_count;
        this.dataTable.options.page = this.$route.query.page * 1 || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async deleteBuilding(item) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `<b>${item.name}</b> épület törlésére készül!<br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) return;

      try {
        const response = await this.$http.get(`buildings/delete/${item.id}`);
        this.fetchBuildings();
        if (response.status === 'OK') {
          this.$dialog.notify.info('Az épület törölve');
        }
      } catch (e) {
        console.error(e);
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        name: this.search.name || '',
        type: this.search.type || ''
      };
    }
  }
};
</script>
