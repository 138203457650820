var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-alert', {
    attrs: {
      "border": "left",
      "type": _vm.type || 'error'
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }