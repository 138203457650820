var render = function render(){
  var _vm$card$owner;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-scroll', {
    staticStyle: {
      "height": "100vh"
    }
  }, [_c('section', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "height": "100vh"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "info"
    }
  }, [_c('v-card-title', [_vm._v(" Test panel "), _c('v-divider'), _c('v-btn', {
    staticClass: "mx-2",
    on: {
      "click": function ($event) {
        return _vm.simulateRead('ABCDEF123456');
      }
    }
  }, [_vm._v("Existing key")]), _c('v-btn', {
    staticClass: "mx-2",
    on: {
      "click": function ($event) {
        return _vm.simulateRead('ABCDEF12345');
      }
    }
  }, [_vm._v("Not existing key")]), _c('v-divider'), _c('v-btn', {
    staticClass: "mx-2",
    on: {
      "click": function ($event) {
        return _vm.simulateRead('FD1F4323');
      }
    }
  }, [_vm._v("Allowed card")]), _c('v-btn', {
    staticClass: "mx-2",
    on: {
      "click": function ($event) {
        return _vm.simulateRead('E87138C7');
      }
    }
  }, [_vm._v("Disallowed card")]), _c('v-btn', {
    staticClass: "mx-2",
    on: {
      "click": function ($event) {
        return _vm.simulateRead('FD1F432');
      }
    }
  }, [_vm._v("Not existing card")])], 1)], 1), _c('v-expand-transition', [_c('v-card', {
    staticClass: "mx-auto my-auto",
    attrs: {
      "loading": _vm.loading,
      "width": "960",
      "elevation": "0",
      "color": "transparent"
    }
  }, [_c('v-card-text', [_c('v-row', {
    staticClass: "d-flex justify-center"
  }, [_c('v-expand-transition', [_vm.card ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    staticClass: "d-flex flex-column justify-center align-center",
    attrs: {
      "outlined": "",
      "height": "300px"
    },
    on: {
      "click": _vm.toggleCard
    }
  }, [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-card-account-details")]), _vm._v(" Kártya ")], 1), !_vm.card ? _c('v-card-text', [_vm._v("Érintse a kártyát az olvasóhoz!")]) : _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    attrs: {
      "size": "120",
      "color": "grey ligthen-2",
      "rounded": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "80"
    }
  }, [_vm._v("mdi-account")])], 1), _c('div', {
    staticClass: "ml-3 d-flex flex-column justify-center align-start"
  }, [_c('div', {
    staticClass: "text-h6"
  }, [_vm._v(_vm._s((_vm$card$owner = _vm.card.owner) === null || _vm$card$owner === void 0 ? void 0 : _vm$card$owner.name))]), _c('div', {
    staticClass: "my-2"
  }, [_vm._v("Kártyaszám: " + _vm._s(_vm.card.card_number))]), _c('div', [_vm._v(_vm._s(_vm.card.card_rule_name_hu))])])], 1)], 1)], 1)], 1) : _vm._e()], 1), _c('v-expand-transition', [_vm.key ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    staticClass: "d-flex flex-column justify-center align-center",
    attrs: {
      "outlined": "",
      "height": "300px"
    },
    on: {
      "click": _vm.toggleKey
    }
  }, [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-key")]), _vm._v(" Kulcs ")], 1), !_vm.key ? _c('v-card-text', [_vm._v(" Érintse a kulcsot az olvasóhoz! ")]) : _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', {
    staticClass: "d-flex flex-column justify-center align-start"
  }, [_c('div', {
    staticClass: "text-h6"
  }, [_vm._v(_vm._s(_vm.key.name))]), _c('div', {
    staticClass: "my-2"
  }, [_vm._v("Kulcsszám: " + _vm._s(_vm.key.keyNumber))]), _c('div', {
    staticClass: "my-2"
  }, [_vm._v(" " + _vm._s(_vm.key.building) + " épület " + _vm._s(_vm.key.floor) + ". emelet " + _vm._s(_vm.key.room) + ". szoba ")]), _c('div', [_vm._v("Megjegyzés: " + _vm._s(_vm.key.remark))])])], 1)], 1)], 1) : _vm._e()], 1), _c('v-expand-transition', [_vm.response ? _c('v-col', {
    staticClass: "text-center d-flex flex-column align-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    attrs: {
      "type": _vm.response.color,
      "prominent": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.response.message) + " ")])], 1) : _vm._e()], 1), _c('v-expand-transition', [_vm.card || _vm.key ? _c('v-col', {
    staticClass: "text-center d-flex flex-column align-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.reset
    }
  }, [_vm._v(" Visszaállítás ")])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }