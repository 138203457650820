var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c('v-btn', {
    staticClass: "ml-1",
    attrs: {
      "tile": "",
      "elevation": "0",
      "color": "accent",
      "width": "60",
      "height": "60",
      "title": "Importálás Excel fájlból",
      "disabled": _vm.hasSelect()
    },
    on: {
      "click": function ($event) {
        _vm.popup.import = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-file-excel")])], 1), _c('v-btn', {
    staticClass: "ml-1",
    attrs: {
      "tile": "",
      "elevation": "0",
      "color": "accent",
      "width": "60",
      "height": "60",
      "title": "Mentés",
      "disabled": _vm.hasSelect()
    },
    on: {
      "click": _vm.save
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1), _vm.hasSelect() ? _c('v-btn', {
    staticClass: "ml-1",
    attrs: {
      "tile": "",
      "elevation": "0",
      "color": "accent",
      "width": "60",
      "height": "60",
      "title": "Email küldés"
    },
    on: {
      "click": _vm.mail
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-email-outline")])], 1) : _vm._e()], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Vonalkód beállítás")]), _c('v-expansion-panels', {
    staticClass: "mb-4",
    attrs: {
      "mandatory": !!Object.values(_vm.search).find(function (e) {
        return e !== '';
      })
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-cog")]), _vm._v(" Csoportos beállítás ")], 1)]), _c('v-expansion-panel-content', [_c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c('v-btn', {
    key: "all_select",
    staticClass: "m-1",
    attrs: {
      "disabled": !_vm.visibleAllSelect(),
      "id": "all_select",
      "color": "secondary"
    },
    model: {
      value: _vm.all_select,
      callback: function ($$v) {
        _vm.all_select = $$v;
      },
      expression: "all_select"
    }
  }, [_vm._v(" Összes kijelölése ")])], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, _vm._l(_vm.accessLevels, function (al, index) {
    return _c('v-checkbox', {
      key: index,
      staticClass: "m-1",
      attrs: {
        "label": al.name
      },
      on: {
        "change": _vm.allAccessLevelChange
      },
      model: {
        value: _vm.allAccessLevelId,
        callback: function ($$v) {
          _vm.allAccessLevelId = $$v;
        },
        expression: "allAccessLevelId"
      }
    });
  }), 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c('date-time-picker', {
    staticClass: "m-1",
    attrs: {
      "timePicker": true,
      "datePicker": true,
      "label": "Kezdő dátum",
      "filled": ""
    },
    on: {
      "input": _vm.allStartDateTimeChange
    },
    model: {
      value: _vm.allStartDateTime,
      callback: function ($$v) {
        _vm.allStartDateTime = $$v;
      },
      expression: "allStartDateTime"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_vm.$root.user.barcode_period_fix == 0 ? _c('v-text-field', {
    staticClass: "m-1",
    attrs: {
      "label": "Időtartam",
      "filled": "",
      "type": "number"
    },
    on: {
      "input": _vm.allPeriodChange
    },
    model: {
      value: _vm.allPeriod,
      callback: function ($$v) {
        _vm.allPeriod = $$v;
      },
      expression: "allPeriod"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1), _vm.barcodes.length > 0 ? _c('v-card', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.barcodes
    },
    scopedSlots: _vm._u([{
      key: `item.check`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-checkbox', {
          attrs: {
            "disabled": !item.email_can_be_sent
          },
          model: {
            value: item.selected,
            callback: function ($$v) {
              _vm.$set(item, "selected", $$v);
            },
            expression: "item.selected"
          }
        })];
      }
    }, {
      key: `item.rfid`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('span', [_c('small', [_vm._v(_vm._s(item.rf_id))]), _c('br'), _vm._v(_vm._s(item.barcode_number))]), item.email_sent == 1 ? _c('v-row', {
          staticClass: "m-1"
        }, [_c('v-icon', [_vm._v("mdi-email-outline")]), _c('span', [_vm._v(_vm._s(item.email_sent_user_name) + " (" + _vm._s(item.email_sent_user_email) + ")")])], 1) : _vm._e()];
      }
    }, {
      key: `item.emailDatas`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-row', {
          staticClass: "mt-1"
        }, [_c('v-text-field', {
          attrs: {
            "placeholder": "Kedvezményezett neve",
            "disabled": !item.editable,
            "error-messages": _vm.errors['barcode_name_' + item.id],
            "filled": ""
          },
          on: {
            "keydown": function ($event) {
              item.email_can_be_sent = false;
              item.selected = false;
              _vm.all_select = false;
            }
          },
          model: {
            value: item.name,
            callback: function ($$v) {
              _vm.$set(item, "name", $$v);
            },
            expression: "item.name"
          }
        })], 1), _c('v-row', {
          staticClass: "mb-1"
        }, [_c('v-text-field', {
          attrs: {
            "placeholder": "Kedvezményezett email",
            "disabled": !item.editable,
            "error-messages": _vm.errors['barcode_email_' + item.id],
            "filled": ""
          },
          on: {
            "keydown": function ($event) {
              item.email_can_be_sent = false;
              item.selected = false;
              _vm.all_select = false;
            }
          },
          model: {
            value: item.email,
            callback: function ($$v) {
              _vm.$set(item, "email", $$v);
            },
            expression: "item.email"
          }
        })], 1)];
      }
    }, {
      key: `item.plate`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-row', {
          staticClass: "mb-1"
        }, [_c('v-text-field', {
          attrs: {
            "placeholder": "Rendszám",
            "disabled": !item.editable,
            "error-messages": _vm.errors['barcode_license_plate_' + item.id],
            "rules": _vm.numtextrules,
            "filled": ""
          },
          on: {
            "keydown": function ($event) {
              item.email_can_be_sent = false;
              item.selected = false;
              _vm.all_select = false;
            }
          },
          model: {
            value: item.license_plate,
            callback: function ($$v) {
              _vm.$set(item, "license_plate", $$v);
            },
            expression: "item.license_plate"
          }
        })], 1)];
      }
    }, {
      key: `item.law`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._l(_vm.accessLevels, function (al, index) {
          return _c('v-checkbox', {
            key: index,
            staticClass: "m-1",
            attrs: {
              "disabled": !item.editable,
              "label": al.name,
              "name": 'barcode_access_levels_' + item.id + '[]',
              "id": 'barcode_access_levels_' + item.id + '_' + al.id
            },
            on: {
              "change": function ($event) {
                item.email_can_be_sent = false;
                item.selected = false;
                _vm.all_select = false;
              }
            },
            model: {
              value: item.access_levels,
              callback: function ($$v) {
                _vm.$set(item, "access_levels", $$v);
              },
              expression: "item.access_levels"
            }
          });
        }), _vm._l(_vm.errors, function (error, id) {
          return _c('div', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: error.id == 'barcode_access_levels_' + item.id,
              expression: "error.id == 'barcode_access_levels_' + item.id"
            }],
            key: id,
            staticClass: "form-error"
          }, [_vm._v(" Kötelező ")]);
        })];
      }
    }, {
      key: `item.startDate`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('v-date-time-picker', {
          attrs: {
            "disabled": !item.editable,
            "timePicker": true,
            "datePicker": true,
            "errorMessages": _vm.errors['barcode_start_date_time_' + item.id]
          },
          on: {
            "input": function ($event) {
              item.email_can_be_sent = false;
              item.selected = false;
            }
          },
          model: {
            value: item.start_date_time,
            callback: function ($$v) {
              _vm.$set(item, "start_date_time", $$v);
            },
            expression: "item.start_date_time"
          }
        })];
      }
    }, {
      key: `item.period`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('v-text-field', {
          attrs: {
            "disabled": !item.editable,
            "error-messages": _vm.errors['barcode_period_' + item.id],
            "rules": _vm.numrules
          },
          on: {
            "keydown": function ($event) {
              item.email_can_be_sent = false;
              item.selected = false;
            },
            "keyup": function ($event) {
              return _vm.periodKeyup(item);
            }
          },
          model: {
            value: item.period,
            callback: function ($$v) {
              _vm.$set(item, "period", $$v);
            },
            expression: "item.period"
          }
        })];
      }
    }], null, true)
  })], 1) : _c('div', [_vm._v("Nem található vonalkód.")]), _vm.popup.import ? _c('import-popup', {
    attrs: {
      "requestId": _vm.$route.query.id,
      "dialog": _vm.popup.import
    },
    on: {
      "close": function ($event) {
        _vm.popup.import = false;
      },
      "success": _vm.getBarcodes,
      "show": _vm.showDialog
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }