var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-center",
    staticStyle: {
      "height": "100vh"
    }
  }, [_c('v-card', {
    staticClass: "relative mx-auto"
  }, [_c('v-card-title', [_vm._v("Telepítő")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "ma-3"
  }, [_c('v-text-field', {
    attrs: {
      "label": "Adatbázis url",
      "value": "localhost:3306",
      "filled": ""
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Adatbázis név",
      "value": "belepteto",
      "filled": ""
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Adatbázis felhasználó",
      "type": "admin",
      "value": "root",
      "filled": ""
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Adatbázis jelszó",
      "type": "password",
      "filled": ""
    }
  })], 1), _c('div', {
    staticClass: "ma-3"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "HikVision modul"
    }
  })], 1)]), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "success",
      "large": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-check-bold")]), _vm._v(" Telepítés ")], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "warning",
      "large": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-check-bold")]), _vm._v(" Javítás ")], 1), _c('v-spacer')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }