var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "title": "Importálás fájlból"
    },
    on: {
      "click": function ($event) {
        _vm.showImportDialog = true;
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-file-import")])], 1), _vm.hasRight('KEY_WRITE') ? _c('v-btn', _vm._b({
    attrs: {
      "to": {
        name: 'NewKey'
      },
      "title": "Új kulcs"
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-plus")])], 1) : _vm._e()], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Kulcs lista")]), _c('v-expansion-panels', {
    staticClass: "mb-4",
    attrs: {
      "mandatory": !!Object.values(_vm.search).find(function (e) {
        return e !== '';
      })
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c('v-expansion-panel-content', [_c('v-row', {
    staticClass: "mt-2",
    attrs: {
      "align": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: _vm.$config.inputMasks.name,
      expression: "$config.inputMasks.name"
    }],
    attrs: {
      "label": "Megnevezés",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.name,
      callback: function ($$v) {
        _vm.$set(_vm.search, "name", $$v);
      },
      expression: "search.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Kulcsszekrény",
      "items": _vm.key_cabinets,
      "item-text": "name",
      "item-value": "id",
      "value-comparator": _vm.$config.comparators.isEqual,
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.key_cabinet_id,
      callback: function ($$v) {
        _vm.$set(_vm.search, "key_cabinet_id", $$v);
      },
      expression: "search.key_cabinet_id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 2,
      "md": 2
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Használatban",
      "items": [{
        text: 'Igen',
        value: true
      }, {
        text: 'Nem',
        value: false
      }],
      "value-comparator": _vm.$config.comparators.isEqual,
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.taken,
      callback: function ($$v) {
        _vm.$set(_vm.search, "taken", $$v);
      },
      expression: "search.taken"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "depressed": ""
    },
    on: {
      "click": _vm.fetchItems
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-magnify")]), _vm._v(" Szűrés ")], 1)], 1)], 1)], 1)], 1)], 1), _c('v-card', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: `item.status`,
      fn: function (_ref) {
        var item = _ref.item;
        return [item.taken_by ? _c('v-icon', {
          attrs: {
            "color": "error",
            "title": `A kulcsot elvitte: ${item.taken_by_name} (${item.taken_by_card_number})`
          }
        }, [_vm._v(" mdi-close-box ")]) : _c('v-icon', {
          attrs: {
            "color": "success",
            "title": "A kulcs a helyén van"
          }
        }, [_vm._v("mdi-checkbox-marked")])];
      }
    }, {
      key: `item.taken_by`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', [_c('div', [_vm._v(_vm._s(item.taken_by_name))]), _c('div', {
          staticClass: "text-caption"
        }, [_vm._v(_vm._s(item.taken_by_card_number))])])];
      }
    }, {
      key: `item.name`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('v-sheet', {
          staticClass: "mr-2",
          attrs: {
            "color": `${item.color || 'grey'}`,
            "width": "8",
            "height": "24",
            "rounded": "sm"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(item.name))])], 1)];
      }
    }, {
      key: `item.cabinets`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return _vm._l(item.cabinets, function (cabinet) {
          return _c('v-chip', {
            key: cabinet.id,
            staticClass: "ma-1",
            attrs: {
              "label": "",
              "link": "",
              "small": "",
              "to": {
                name: 'KeyCabinetList',
                params: {
                  highlight: [cabinet.id]
                }
              }
            }
          }, [_vm._v(" " + _vm._s(cabinet.name) + " ")]);
        });
      }
    }, {
      key: `item.actions`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm.hasRight('KEY_WRITE') ? _c('v-btn', {
          staticClass: "rounded-sm mr-1",
          attrs: {
            "text": "",
            "fab": "",
            "small": "",
            "dark": "",
            "color": "primary",
            "to": {
              name: 'KeyEditor',
              params: {
                id: item.id
              }
            },
            "title": "Szerkesztés"
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1) : _vm._e(), _vm.hasRight('KEY_WRITE') ? _c('v-btn', {
          staticClass: "rounded-sm mr-1",
          attrs: {
            "text": "",
            "fab": "",
            "small": "",
            "dark": "",
            "color": "primary",
            "title": "Kártyajogok törlése"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteCardPermissions(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "error"
          }
        }, [_vm._v("mdi-card-remove")])], 1) : _vm._e(), _vm.hasRight('KEY_WRITE') ? _c('v-btn', {
          staticClass: "rounded-sm ma-1",
          attrs: {
            "text": "",
            "fab": "",
            "small": "",
            "dark": "",
            "color": "primary",
            "title": "Archiválás"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteKey(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "error"
          }
        }, [_vm._v("mdi-archive")])], 1) : _vm._e()];
      }
    }], null, true)
  })], 1), _c('import-dialog', {
    on: {
      "close": function ($event) {
        _vm.showImportDialog = false;
      },
      "success": _vm.fetchKeys
    },
    model: {
      value: _vm.showImportDialog,
      callback: function ($$v) {
        _vm.showImportDialog = $$v;
      },
      expression: "showImportDialog"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }