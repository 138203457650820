var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    staticClass: "flex-column flex-wrap align-start justify-start px-4",
    attrs: {
      "fixed": "",
      "height": _vm.headerHeight,
      "elevation": "0",
      "color": "transparent",
      "app": ""
    }
  }, [_c('v-app-bar-nav-icon', {
    staticClass: "rounded-sm ml-0",
    on: {
      "click": function ($event) {
        _vm.$store.state.drawer = !_vm.$store.state.drawer;
      }
    }
  }), _c('v-btn', {
    attrs: {
      "icon": "",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$store.commit('openSearch');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-magnify")])], 1), _c('v-spacer'), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mx-1 rounded-sm",
          attrs: {
            "text": "",
            "small": "",
            "height": "40",
            "color": _vm.uniomd_unreachable ? 'error' : 'success',
            "to": {
              name: 'Status'
            }
          }
        }, 'v-btn', attrs, false), on), [_c('span', {
          staticClass: "d-sm-inline-block d-none mr-2"
        }, [_vm._v("Státusz")]), _vm.uniomd_unreachable ? [_c('v-icon', {
          attrs: {
            "color": "error"
          }
        }, [_vm._v("mdi-alert-circle")])] : [_c('v-icon', {
          attrs: {
            "color": "success"
          }
        }, [_vm._v("mdi-check-circle")])]], 2)];
      }
    }])
  }, [_c('span', [_vm._v(" Szolgáltatás állapota: " + _vm._s(_vm.uniomd_unreachable ? 'HIBA' : 'OK') + " ")])]), _c('v-menu', {
    attrs: {
      "transition": "slide-y-transition",
      "right": "",
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mx-1 px-1 rounded-sm",
          attrs: {
            "text": "",
            "small": "",
            "height": "40"
          }
        }, 'v-btn', attrs, false), on), [_c('v-avatar', {
          staticClass: "rounded-sm",
          attrs: {
            "color": "gray lighten-4",
            "size": "32"
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "24",
            "color": "gray"
          }
        }, [_vm._v("mdi-account")])], 1), _c('div', {
          staticClass: "data text-left mx-2"
        }, [_c('div', {
          staticClass: "font-weight-bold text-subtitle-2",
          staticStyle: {
            "text-transform": "none"
          }
        }, [_vm._v(" " + _vm._s(_vm.$store.state.user.name) + " ")])]), _c('v-icon', {
          attrs: {
            "size": "24"
          }
        }, [_vm._v("mdi-chevron-down")])], 1)];
      }
    }])
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex pa-2",
    attrs: {
      "color": "white"
    }
  }, [_c('v-avatar', {
    staticClass: "rounded-sm",
    attrs: {
      "color": "grey lighten-4",
      "size": "64"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "48",
      "color": "grey"
    }
  }, [_vm._v("mdi-account")])], 1), _c('div', {
    staticClass: "d-flex flex-column justify-center pa-2 ml-2"
  }, [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$store.state.user.name) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$store.state.user.email) + " ")])])], 1), _c('div', {
    staticClass: "d-flex flex-column justify-center pa-2"
  }, [_c('v-btn', {
    staticClass: "mb-1",
    attrs: {
      "text": "",
      "small": "",
      "block": "",
      "to": "/change-password",
      "height": "36"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-key-variant")]), _vm._v(" Jelszó módosítás ")], 1), _c('v-btn', {
    staticClass: "mb-1",
    attrs: {
      "text": "",
      "small": "",
      "block": "",
      "height": "36"
    },
    on: {
      "click": function ($event) {
        return _vm.$store.commit('toggleDarkMode');
      }
    }
  }, [_vm.$vuetify.theme.dark ? [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-white-balance-sunny")]), _vm._v(" Váltás világos módra ")] : [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-weather-night")]), _vm._v(" Váltás sötét módra ")]], 2)], 1), _c('v-sheet', {
    attrs: {
      "color": "primary",
      "height": "2"
    }
  }), _c('v-sheet', {
    staticClass: "pa-2",
    attrs: {
      "color": "muted"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "block": "",
      "small": ""
    },
    on: {
      "click": _vm.$logout
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-power")]), _vm._v(" Kijelentkezés ")], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }