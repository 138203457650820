var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-footer', {
    staticClass: "d-flex justify-space-between flex-wrap py-0",
    style: `margin-bottom: -${_vm.footerHeight}px`,
    attrs: {
      "app": "",
      "inset": "",
      "height": _vm.footerHeight,
      "color": "transparent"
    }
  }, [_c('div', [_vm._v("© 2017-" + _vm._s(_vm.year) + " Uninet Kft. "), _c('span', [_vm._v("- Minden jog fenntarva.")])]), _c('div', {
    staticClass: "text-caption text-right"
  }, [_c('div', {
    attrs: {
      "title": `Build #${_vm.buildNumber}\n${_vm.buildDate}`
    }
  }, [_vm._v("V" + _vm._s(_vm.version))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }